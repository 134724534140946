/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import HeroPattern from "./hero-pattern"
import FeaturedStoryHeroPattern from "./featured-story-hero-pattern"
import AccordionPattern from "./accordion-pattern"
import TabsPattern from "./tabs-pattern"
import CarouselPattern from "./carousel-pattern"
import BlogPattern from "./blog-pattern"
import BasicPattern from "./basic-pattern"
import StaffAboutPattern from "./staff-about-pattern"
import TitleAboutPattern from "./title-about-pattern"
import GrantsAboutPattern from "./grants-about-pattern"
import GreaterAboutPattern from "./greater-about-pattern"
import AcknowledgmentsSectionPattern from "./acknowledgments-about-pattern"
import SwipeableViewPattern from "./swipeable-view-pattern"
import OrganizationFormPattern from "./organization-form-pattern"
import EventFormPattern from "./event-form-pattern"
import AccentPattern from "./accent-pattern"
import EventsCalendarPattern from "./events-calendar-pattern"
import EventDetailPattern from "./event-detail-pattern"
import CollectionDetailPattern from "./collection-detail-pattern"
import ExperiencesPattern from "./experiences-pattern"
import CategoriesPattern from "./categories-pattern"
import ContactFormPattern from "./contact-form-pattern"
import HighlightsPattern from "./highlights-pattern"
import SubmissionPolicyPattern from "./submission-policy-pattern"
import FeaturedStoriesPattern from "./featured-stories-pattern"
import RecentlyViewedPattern from "./recently-viewed-pattern"
import RecentStoriesPattern from "./recent-stories-pattern"

const Blade = ({ blade, desktopAdUrl, mobileAdUrl, ...restProps }) => {
  const patterns = {
    Hero: (
      <HeroPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    FeaturedStoryHero: (
      <FeaturedStoryHeroPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    Accordion: (
      <AccordionPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    Carousel: (
      <CarouselPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    SwipeableView: (
      <SwipeableViewPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    Tabs: (
      <TabsPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    Blog: (
      <BlogPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    Basic: (
      <BasicPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    TitleAbout: (
      <TitleAboutPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    GreaterAbout: (
      <GreaterAboutPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    StaffAbout: (
      <StaffAboutPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    GrantsSectionAbout: (
      <GrantsAboutPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    AcknowledgmentsSection: (
      <AcknowledgmentsSectionPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    Accent: (
      <AccentPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    OrganizationForm: (
      <OrganizationFormPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    EventForm: (
      <EventFormPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    EventsCalendar: (
      <EventsCalendarPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    EventDetail: (
      <EventDetailPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    CollectionDetail: (
      <CollectionDetailPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    Experiences: (
      <ExperiencesPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    Highlights: (
      <HighlightsPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    // Categories: (
    //   <CategoriesPattern
    //     blade={blade}
    //     {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
    //   />
    // ),
    ContactForm: (
      <ContactFormPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    SubmissionPolicy: (
      <SubmissionPolicyPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    FeaturedStories: (
      <FeaturedStoriesPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    RecentlyViewed: (
      <RecentlyViewedPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
    RecentStories: (
      <RecentStoriesPattern
        blade={blade}
        {...{ desktopAdUrl, mobileAdUrl, ...restProps }}
      />
    ),
  }
  return patterns[blade.patternType] || null
}

Blade.propTypes = {
  blade: PropTypes.object,
}
export default Blade
